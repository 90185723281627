import banner_about from '../images/about_logo.png'
import content01 from '../images/man-socal.jpg'
import content02 from '../images/man-giving.jpg'
import { Fade } from 'react-awesome-reveal'
import { Link } from 'react-router-dom'
export default function About() {
    return (
        <>
            <div className="container">
                <img src={banner_about} alt="" width="100%" />
                <h1 id="red"><b>The Spy</b></h1>
                <p id="textindent2">เราคือสำนักงานนักสืบเอกชนที่ได้มาตรฐาน มีสำนักงาน และจดทะเบียนอย่างถูกต้องตามกฏหมาย
                    เรานักสืบ เดอะสปาย นักสืบเอกชน ยินดีให้คำปรึกษา และพร้อมปฏิบัติงานให้กับท่านทุกเวลา และทุกสถานที่ หน้าที่ของเราก็คือ สืบค้นหาความจริงจากสิ่งที่ท่านไม่รู้ หรือสิ่งที่ท่านเห็น มันอาจไม่เป็นจริงอย่างที่ท่านคิด
                    ทุกอย่างอยู่ใต้การทำงานที่เป็นทีมเวิร์ค ที่ประกอบไปด้วย บุคคลากรที่มีความรู้ความชำนาญเฉพาะด้าน, เทคโนโลยีที่ทันสมัย, เครื่องมือและอุปกรณ์ที่เหมาะสมกับงานแต่ละประเภท
                    และที่สำคัญทีมงานที่มากด้วยประสบการณ์ทำงานด้านงานสืบกว่า 10 ปี ทางเรามีการวางแผนกำหนดวิธีการทำงานก่อนที่จะลงพื้นที่ปฏิบัติงานจริง ทุกครั้ง
                    ข้อมูลของผู้ว่าจ้างทุกอย่างจะถูกปกปิดเป็นความลับ จะไม่มีการเปิดเผยข้อมูลของผู้ว่าจ้างไม่ว่ากรณีใดๆทั้งสิ้น ไม่มีการนำข้อมูลของท่านไปเปิดเผย ให้กับฝ่ายตรงข้ามหรือว่ามีการเรียกร้องค่าใช้จ่ายเพิ่มเติมทีหลังจากที่มีการตกลงให้มีการจ้างสืบ
                    ข้อมูลทุกอย่างที่เราสืบค้นมาให้กับทางท่าน เป็นข้อมูลที่เป็นจริงถูกต้อง 100% และเป็นข้อมูลที่ได้มาจากแหล่งข้อมูล ที่มาจากองค์กรที่เชื่อถือได้ ทางด้านงานสืบพฤติกรรมเราสามารถค้นหาหรือติดตามความเคลื่อนไหวของเป้าหมายได้ตลอดเวลา
                    ท่านจึงมั่นใจได้ว่าทางเราจะสามารถทราบการเคลื่อนไหวของบุคคล หรือเป้าหมายได้ตลอดเวลา หลักฐานหรือข้อมูลทุกอย่างที่ได้มาจะถูกบันทึกตามวันเวลา ซึ่งท่านสามารถนำไปเป็นข้อมูลอ้างอิงหรือนำไปเป็นหลักฐานประกอบพิจารณาคดีได้</p>

                {/*  */}
                <h2><b>สิ่งสำคัญที่ทำให้ท่านพิจารณาเลือกเราทำงานให้กับท่าน</b></h2>
                <p id="textindent2" >
                    นั่นก็คือเรื่องของจรรยาบรรณที่ทางเรามีให้กับลูกค้าเสมอมา กลุ่มลูกค้าจองเราในปัจจุบันนี้ส่วนมากจะเป็นลูกค้าที่เคยร่วมงานกับทางเรา และก็พึงพอใจกับผลงานที่ทางเราสืบค้นมาให้กับท่านจนทำให้มีการแนะนำกัน แบบบอกต่อๆกันไปแน่นอนว่า ลูกค้าท่านใหม่ที่ติดต่อมาหาเรา โดยผ่านการแนะนำโดยวิธีนี้ก็จะรู้สึกสบายใจ และไม่ต้องกังวลว่าถูกหลอก ถูกโกง หรือทางเราจะทำงานให้กับท่านได้คุ้มค่ากับเงินที่ท่านเสียไปหรือไม่ ? เพราะว่าบุคคลที่ท่านรู้จักหรือแนะนำมาให้เราทำงานให้กับท่านนั้นจะเป็นคนรับรองการทำงานของเราที่ได้รับมอบหมายจากท่านได้เป็นอย่างดี
                    สิ่งสำคัญอันดับแรกที่ท่านต้องเตรียมให้กับเราก็คือ "ข้อมูล" ที่เกี่ยวข้อง หรือที่จำเป็นต้องใช้ในงานสืบเท่านั้น ข้อมูลส่วนตัวของลูกค้าที่ไม่เกี่ยวข้องกับงานสืบ ทางเราจะไม่เข้าไปยุ่งเกี่ยวเป็นอันขาด เพราะเราจะเคารพสิทธิความเป็นส่วนตัวของลูกค้ามาเป็นอันดับแรก
                    ราคาหรือค่าใช้จ่ายที่ท่านต้องเสียให้กับทางเรานั้น เราจะพิจารณาจากสิ่งที่ท่านต้องการเป็นหลัก โดยทั่วไปนั้นค่าใช้จ่ายของงานประเภทนี้จะไม่มีราคาที่ตายตัวแน่นอน ขึ้นอยู่กับว่าแต่ละบริษัทนั้นจะคิดราคาจากท่านมากน้อยเพียงใด
                    แต่สำหรับเราเรื่องของค่าใช้จ่ายที่ท่านต้องจ่ายให้กับทางเรานั้น เรากล้ารับประกันว่าเป็นราคาที่เป็นธรรม และคุ้มค่ากับเงินที่ท่านเสียไปอย่างแน่นอน ถ้าท่านกำลังมองหานักสืบมืออาชีพที่มีทักษะ และมุ่งเน้นเรื่องจรรยาบรรณทีมีให้ต่อลูกค้า ทางเราขอเสนอตัวเพื่อเป็นทางเลือกให้ท่านพิจารณาให้โอกาสเราได้มอบหมายทำงานให้กับท่าน แล้วท่านจะพบว่าทุกอย่างที่กล่าวมาทั้งหมดนั้นไม่ใช่แค่การกล่าวอ้าง
                </p>

                {/*  */}
                <h2><b>วัตถุประสงค์หลักของเรา</b></h2>
                <p id="textindent2">
                    ประกอบกิจการให้บริการด้านงานสืบ อาทิเช่น สืบชู้สาว ติดตามพฤติกรรม สืบสวนสอบสวนหาข้อมูล และงานสืบค้นทุกประเภท
                    บริการบริหารจัดการงานด้านกฎหมาย อาทิเช่น ให้คำปรึกษาคดีความ จัดหาทนาย ว่าความทั้งคดีแพ่ง คดีอาญา รับช่วงคดีความ และสืบทรัพย์บังคับคดี

                    <li>เรามุ่งมั่นตอบสนองควาใพึงพอใจสูงสุดของลูกค้า ที่รับบริการกับเรา</li>
                    <li>เราฝึกฝนและพัฒนาความสามารถของบุคลากรและเครื่องมือในองค์กรของเราให้มีความชำนาญ ทั้นสมัย เป็นมืออาชีพ อย่างสม่ำเสมอ</li>
                </p>
                <div className="row justify-content-center mt-3">
                    <div className="col-12 col-md-4 col-lg-4 text-center">
                        <img src={content01} alt="content01" width="100%" />
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 text-center">
                        <img src={content02} alt="content02" width="100%" />
                    </div>
                </div>
                <hr />
                <Fade >
                    <div className="row justify-content-center">
                        <div className="text-center my-3">
                            <h1 id="red"><b>ติดต่อเรา The Spy</b></h1>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 text-center">
                            <label htmlFor=""><h2><b>โทรศัพท์</b></h2></label>
                            <Link id='noneline' to="tel:0876394141" target="_blank">
                                <h3><b>087-639-4141</b></h3>
                            </Link>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 text-center">
                            <label htmlFor=""><h2><b>Line ID</b></h2></label>
                            <Link id='noneline' to="http://line.me/ti/p/0okvKJiUkD" target="_blank">
                                <h3><b>thespythai</b></h3>
                            </Link>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 text-center">
                            <label htmlFor=""><h2><b>Email</b></h2></label>
                            <Link id='noneline' to="mailto:the_spythai@hotmail.com" target="_blank">
                                <h3><b>the_spythai@hotmail.com</b></h3>
                            </Link>
                        </div>
                    </div>
                </Fade>
            </div>
            <hr />
        </>
    )
}