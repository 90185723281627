import React, { useEffect } from 'react';

export default function Footer() {
    useEffect(() => {
        document.getElementById("year").innerHTML = new Date().getFullYear();
    }, []);

    return (
        <footer>
            <div className="container">
                
                <div className="row">
                    <div className="col text-center">
                        <p>&copy; <span id="year"></span> The Spy Thai. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
