import { Link } from 'react-router-dom'
import contact_logo from '../images/contact_logo.png'
// import image_content from "../images/contact_img.jpg"
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
export default function Contact() {
    const [namecustomer, setnamecustomer] = useState("")
    const [emailcustomer, setemailcustomer] = useState("")
    const [phonecustomer, setphonecustomer] = useState("")
    const [detailcustomer, setdetailcustomer] = useState("")
    const containerStyle = {
        width: "100%",
        height: "400px",
    };

    const center = { lat: 13.928396, lng: 100.58251 };

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyA_DeZlR7mlQPPcxK-l_GSh1hd5JoUIV0E",
    });

    const onLoad = (marker) => {
        // console.log("marker: ", marker);
    };
    const handlesenmail = () => {
        axios.post("https://thespythai.com/services/", {
            function: "sendmail", namecustomer: namecustomer,
            emailcustomer: emailcustomer,
            phonecustomer: phonecustomer,
            detailcustomer: detailcustomer,
        }).then((res) => {
            if (res.data.status === 200) {
                Swal.fire("แจ้งเตือน", "ส่งข้อมูลสำเร็จ", "success").then(() => {
                    window.location.reload()
                });
            } else {
                Swal.fire("แจ้งเตือน", "ส่งข้อมูลไม่สำเร็จ", "error");
            }
        })
    }
    return (
        <>
            <div className="container">
                <img src={contact_logo} alt="contact_logo" width="100%" />
                <div className="row justify-content-center my-3">
                    <div className="col-12 col-md-6 col-lg-6">
                        <h1>ติดต่อเรา</h1>
                        <h2>กรุงเทพมหานคร ประเทศไทย</h2>
                        <Link id='noneline' to="tel:0876394141" target="_blank">
                            <h2>โทรศัพท์ : 087-639-4141</h2>
                        </Link>
                        <Link id='noneline' to="http://line.me/ti/p/0okvKJiUkD" target="_blank">
                            <h2>Line : thespythai</h2>
                        </Link>
                        <Link id='noneline' to="mailto:the_spythai@hotmail.com" target="_blank">
                            <h2>Email : the_spythai@hotmail.com</h2>
                        </Link>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        {/* <img src={image_content} alt="image_content" width="100%"/> */}
                        {isLoaded ? (
                            <>
                                <div className="row justify-content-center mb-5">
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={center}
                                        zoom={15}
                                        onLoad={onLoad}
                                    >
                                        <MarkerF
                                            onLoad={onLoad}
                                            position={{
                                                lat: parseFloat(13.928396),
                                                lng: parseFloat(100.58251),
                                            }}
                                        />
                                    </GoogleMap>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-12 col-md-8 col-lg-8">
                        <label htmlFor="namecustomer">ชื่อ :</label>
                        <input type="text" name="namecustomer" placeholder='ชื่อ...' className='form-control' id="namecustomer" onChange={(e) => setnamecustomer(e.target.value)} />
                        <label htmlFor="emailcustomer">Email :</label>
                        <input type="email" name="emailcustomer" placeholder='อีเมล์...' className='form-control' id="emailcustomer" onChange={(e) => setemailcustomer(e.target.value)} />
                        <label htmlFor="phonecustomer">เบอร์โทรศัพท์ :</label>
                        <input type="tel" name="phonecustomer" placeholder='เบอร์โทรศัพท์...' className='form-control' id="phonecustomer" onChange={(e) => setphonecustomer(e.target.value)} />
                        <label htmlFor="detailcustomer">รายละเอียด :</label>
                        <div className="form-floating">
                            <textarea className="form-control" placeholder="Leave a comment here" id="detailcustomer" style={{ height: "100px" }} onChange={(e) => setdetailcustomer(e.target.value)} ></textarea>
                            <label for="detailcustomer">รายละเอียด</label>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="col-4 col-md-4 col-lg-4 text-center">
                            <button type="button" className='btn btn-success form-control' onClick={handlesenmail}>ส่งข้อความ</button>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        </>
    )
}