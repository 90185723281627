import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // นำเข้า BrowserRouter และ alias เป็น Router
import Indexpage from './pages';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import About from './pages/About';
import Service from './pages/Service';
import Contact from './pages/Contact';
import Knowledge from './pages/Knowledge';

export default function App() {
  return (
    <Router> 
      <>
        <Navbar />
        <Routes>
          <Route path="/" element={<Indexpage />} />
          <Route path="/About" element={<About />} />
          <Route path='/Service' element={<Service/>}/>
          <Route path='/Contact' element={<Contact/>}/>
          <Route path='/knowledge' element={<Knowledge/>}/>
        </Routes>
        <Footer/>
      </>
    </Router>
  );
}

