import banner01 from "../images/slider_animation.gif"
import bannercontent1 from "../images/bannercontent1.jpg"
import bannercontent2 from "../images/gps.jpg"
import icon1 from "../images/love_9578066.png"
import icon2 from "../images/balance_2387610.png"
import icon3 from "../images/top-secret_3160110.png"
import external_link01 from "../images/external_link01.png"
import external_link02 from "../images/external_link02.png"
import external_link03 from "../images/external_link03.png"
import external_link04 from "../images/external_link04.png"
import external_link05 from "../images/external_link05.png"
import external_link06 from "../images/external_link06.png"
import external_link07 from "../images/external_link07.png"
import external_link08 from "../images/external_link08.png"
import { Fade } from "react-awesome-reveal"
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import { Link } from "react-router-dom"
export default function Indexpages() {
    const images = [
        {
            image: external_link01,
            link: "https://www.royalthaipolice.go.th/"
        }, {
            image: external_link02,
            link: "https://www.m-society.go.th/"
        }, {
            image: external_link03,
            link: "https://www.sso.go.th/wpr/"
        }, {
            image: external_link04,
            link: "https://www.excise.go.th/excise/index.htm"
        }, {
            image: external_link05,
            link: "https://www.dsi.go.th/"
        }, {
            image: external_link06,
            link: "https://www.cib.go.th/"
        }, {
            image: external_link07,
            link: "https://www.oncb.go.th/welcomePage/20240216.html"
        }, {
            image: external_link08,
            link: "https://www.nhso.go.th/"
        }
    ]
    //Image Slider//



    return (
        <>
            <div className="container">
                <img src={banner01} alt="" width="100%" />
                <Fade direction="left">
                    <div className="row justify-content-center my-3">
                        <div className="col-12 col-md-6 col-lg-6">
                            <img src={bannercontent1} alt="" width="100%" />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <h1 id="red"><b>The Spy</b></h1>
                            <p id="textindent">เราคือสำนักงานสืบเอกชนที่ได้มาตรฐาน <br /> มีสำนักงาน และจดทะเบียนอย่างถูกต้องตามกฏหมาย เรานักสืบ เดอะสปาย นักสืบเอกชน ยินดีให้คำปรึกษา และบริการงานสืบทุกประเภท เช่น <br /> สืบชู้สาว ติดตามพฤติกรรมต่างๆ และอื่นๆ รับให้คำปรึกษาด้านกฏหมายโดยทีมงานที่เป็นมืออาชีพทั่วราชอาณาจักร </p>
                        </div>
                    </div>
                </Fade>
                <Fade direction="right">
                    <div className="row justify-content-center my-2">
                        <div className="col-12 col-md-6 col-lg-6">
                            <h1 id="red"><b>The Spy</b></h1>
                            <p id="textindent">เรายินดีสืบหาความจริงในสิ่งที่คุณอยากรู้ได้ทุกๆเรื่อง เน้นประสิทธิภาพของผลงานตามจุดประสงค์ของท่าน ทำงานมีระบบขั้นตอน วิเคราะห์ข้อมูล และวางแผน อย่ารอบคอบ ก่อนปฏิบัติงานจริง และยังมีอุปกรณ์ที่ทันสมัย ที่ช่วยให้การปฏิบัติงานต่างๆออกมาได้ผลดี มีการรายงานผลและถ่ายภาพด้วยวิธีต่างๆ สำหรับข้อมูลที่ท่านจะนำไปใช้ เช่น ขึ้นศาล หรือ หลักฐานต่างๆ ซึ่งเป็นประโยชน์ต่อตัวท่านเอง เดอะสปาย เราเน้นประสิทธิภาพการทำงานสูงสุด ความสำเร็จของงาน คือการบริการของเรา </p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <img src={bannercontent2} alt="" width="100%" />
                        </div>
                    </div>
                </Fade>
                <Fade>
                    <div className="row justify-content-center my-2">
                        <div className="col-12 col-md-4 col-lg-4 text-center">
                            <img src={icon3} alt="" width="200px" />
                            <h1><b>ข้อมูลลูกค้า</b></h1>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 text-center">
                            <img src={icon1} alt="" width="200px" />
                            <h1><b>ความซื่อสัตย์สุจริต</b></h1>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 text-center">
                            <img src={icon2} alt="" width="200px" />
                            <h1><b>จรรยาบรรณต่อลูกค้า</b></h1>
                        </div>
                    </div>
                </Fade>
                <Fade direction="down">
                    <div className="row justify-content-center mt-2">
                        <div className="text-center">
                            <h1 id="red"><b>The Spy</b></h1>
                            <h2>"เรายินดีให้บริการด้วยความจริงใจ"</h2>
                            <h2>"เราจะเก็บข้อมูลและความลับของลูกค้าเป็นอย่างดี"</h2>
                            <h2>"เราทำงานด้วยความซื่อสัตย์สุจริต มีจรรยาบรรณ และคุณธรรม"</h2>
                        </div>
                    </div>
                </Fade>
                <div className="row justify-content-center mt-3">

                </div >
                <div className="row justify-content-center mt-3">
                    <Slide autoplay pauseOnHover >
                        {images.map((val, key) => (
                            <>

                                <div div className="text-center" key={key} >
                                    <Link to={val.link} target="_blank" >
                                        <img src={val.image} alt="" />
                                    </Link>
                                </div>
                            </>
                        ))
                        }
                    </Slide >
                </div >
                <hr />
                <Fade >
                    <div className="row justify-content-center">
                        <div className="text-center my-3">
                            <h1 id="red"><b>ติดต่อเรา The Spy</b></h1>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 text-center">
                            <label htmlFor=""><h2><b>โทรศัพท์</b></h2></label>
                            <Link id='noneline' to="tel:0876394141" target="_blank">
                                <h3><b>087-639-4141</b></h3>
                            </Link>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 text-center">
                            <label htmlFor=""><h2><b>Line ID</b></h2></label>
                            <Link id='noneline' to="http://line.me/ti/p/0okvKJiUkD" target="_blank">
                                <h3><b>thespythai</b></h3>
                            </Link>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 text-center">
                            <label htmlFor=""><h2><b>Email</b></h2></label>
                            <Link id='noneline' to="mailto:the_spythai@hotmail.com" target="_blank">
                                <h3><b>the_spythai@hotmail.com</b></h3>
                            </Link>
                        </div>
                    </div>
                </Fade>
            </div >
            <div className="mb-5"></div>
        </>
    )
}