import { Link } from "react-router-dom"
import knowledge_logo from "../images/knowledge_logo.png"
import imagecontent1 from "../images/unrecognizable-couple-with-balloons.jpg"
import imagecontent2 from "../images/court-hammer-books-judgment-law-concept.jpg"
import imagecontent3 from "../images/race-highway-black-red-coupe-cars.jpg"
import imagecontent4 from "../images/red-vintage-motorcycle.jpg"
import imagecontent5 from "../images/happy-women-traveling-train-vacation-travel-ideas_1150-14786.jpg"
import imagecontent6 from "../images/groom-bride-hotel.jpg"
import imagecontent7 from "../images/thieves-wear-black-hat-hold-phone-smart-card-gray.jpg"
import imagecontent8 from "../images/black-wrapped-gifts-with-tag-plain-background.jpg"
import { useState } from "react"
import { Fade } from "react-awesome-reveal"
export default function Knowledge() {
    const [showdata1, setshowdata1] = useState(false)
    const handleshowdata1 = () => {
        setshowdata1(!showdata1)
    }
    const [showdata2, setshowdata2] = useState(false)
    const handleshowdata2 = () => {
        setshowdata2(!showdata2)
    }
    const [showdata3, setshowdata3] = useState(false)
    const handleshowdata3 = () => {
        setshowdata3(!showdata3)
    }
    const [showdata4, setshowdata4] = useState(false)
    const handleshowdata4 = () => {
        setshowdata4(!showdata4)
    }
    const [showdata5, setshowdata5] = useState(false)
    const handleshowdata5 = () => {
        setshowdata5(!showdata5)
    }
    return (
        <>
            <div className="container">
                <img src={knowledge_logo} alt="knowledge_logo" width="100%" />
                <h1 className="my-3"><b>เกร็ดความรู้</b></h1>
                <Link id="noneline" onClick={handleshowdata1}>
                    <li>เหตุฟ้องอย่าได้ ตามประมวลกฎหมายแพ่งและพาณิชย์ ดังนี้</li>
                </Link>
                {showdata1 && (
                    <>
                        <div className="mt-3">   </div>

                        <div className="text-center">
                            <h3 style={{ color: "red" }}>เหตุฟ้องหย่าได้ ตามประมวลกฎหมายแพ่งและพาณิชย์ มีดังนี้</h3>
                        </div>
                        <div className="row justify-content-center mt-2">
                            <div className="col-12 col-md-6 col-lg-6 ">
                                <p id="textindent2">สามีภริยาที่จดทะเบียนสมรสถูกต้องตามกฎหมายนั้น ถ้าจะหย่าขาดจากกัน
                                    ก็ทำได้ โดยวิธีไปจดทะเบียนหย่าที่อำเภอ หรือไปฟ้องหย่าที่ศาล การพ้องหย่านั้นไม่ใช่
                                    อยู่ดีๆ อย่ากหย่าก็ไปฟ้องหย่า จะต้องมีเหตุหย่าได้ตามที่กฎหมายกำหนดไว้ เช่น อีกฝ่าย
                                    ทิ้งไปเป็นเวลาหลายปี อวัยวะสืบพันธุ์ของฝ่ายใดฝ่ายหนึ่งใช้การไม่ได้ ทำร้ายร่างกายกัน
                                    อย่างร้ายแรง ภรรยามีชู้ หรือ สามีมีเมียน้อย กฎหมายกำหนดเหตุหย่าไว้หลายประการ</p>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 text-center">
                                <img src={imagecontent1} alt="imagecontent1" width="50%" />
                            </div>
                        </div>
                        <h3 className="my-3" style={{ color: "red" }}>ประมวลกฎหมายแพ่งและพาณิชย์ มาตรา 1516 ข้อ 6 กำหนดเหตุฟ้องหย่าไว้ดังนี้</h3>
                        <div className="row justify-content-center mt-2">
                            <div className="col-12 col-md-6 col-lg-6 text-center">

                                <img src={imagecontent2} alt="imagecontent1" width="60%" />
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 ">
                                <p id="textindent2">มาตรา 1516 สามี หรือภริยาไม่ให้ความช่วยเหลืออุปการะเลี้ยงดูอีกฝ่ายหนึ่งตาม
                                    สมควร หรือทำการเป็นปฏิปักษ์ต่อการที่เป็นสามี หรือภริยากันอย่างร้ายแรง ทั้งนี้ถ้า
                                    การกระทำนั้นถึงขนาดที่อีกฝ่ายหนึ่งเดือดร้อนเกินควรในเมื่อเอาสภาพ ฐานะ และความ
                                    เป็นอยู่ร่วมกันฉันสามีภริยามาคำนึงประกอบ อีกฝ่ายหนึ่งนั้นฟ้องหย่าได้</p>
                            </div>
                        </div>

                        <hr />
                    </>
                )}
                <Link id="noneline" onClick={handleshowdata2}>
                    <li>รถที่ติดภาระเช่าซื้อ จะซื้อจะขายหรือจะโอนให้บุคคลอื่นกันอย่างไรจึงจะปลอดภัย</li>
                </Link>
                {showdata2 && (
                    <>
                        <div className="mt-3">   </div>
                        <div className="text-center">
                            <h3 style={{ color: "red" }}>รถที่ติดภาระเช่าซื้อ จะซื้อจะขายหรือจะโอนให้บุคคลอื่นกันอย่างไรจึงจะปลอดภัย</h3>
                        </div>
                        <div className="row justify-content-center mt-2">
                            <div className="col-12 col-md-6 col-lg-6 text-center">
                                <img src={imagecontent3} alt="imagecontent3" width="75%" />
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <p id="textindent2">
                                    ท่านที่จะขายรถให้ผู้อื่น ไม่ว่าจะขายให้เต็นท์รถมือสองหรือขายให้กับผู้อื่นต่อไป
                                    ใช้โดยตรง ซึ่งผมจะเรียกว่า ถ้าจะให้ปลอดภัยยิ่งขึ้น ท่านต้องขอถ่ายสำเนาใบเสร็จรับเงิน
                                    งวดสุดท้ายนี้เก็บไว้เป็นหลักฐาน แต่ถ้าเป็นการโอนสิทธิการเช่าซื้อไปให้เขาผ่อนต่อ ท่าน
                                    ก็พาผู้ซื้อไปที่บริษัทไฟแนนซ์ให้เขาจัดการทำสัญญาโอนสิทธิการเช่าซื้อหรืออนุมัติให้โอน
                                    สิทธิจึงจะเสร็จขั้นตอน ที่สำคัญท่านต้องขอถ่ายสำเนาสัญญาการโอน-รับโอนสิทธิการ
                                    เช่าซื้อจาก บริษัทไฟแนนซ์ให้ได้ก่อนที่ท่านจะมอบรถให้กับผู้ซื้อหรือผู้รับโอนไป จึงจะจบขั้นตอน
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-2">
                            <div className="col-12 ">
                                {/* <img src={imagecontent3} alt="imagecontent3" width="75%" /> */}
                                <p id="textindent2">
                                    มีเหตุเกิดขึ้นจำนวนมาก ท่านที่ขายรถหรือโอนสิทธิการเช่าซื้อไปให้เขาผ่อนต่อ
                                    แต่ไม่ได้ทำตามขบวนการดังกล่าว ต่อมาท่านก็ได้รับหมายศาลจากบริษัทไฟแนนซ์ เพราะ
                                    ว่าเมื่อผู้ซื้อรับรถจากท่านไปแล้วแต่ผู้ซื้อไม่ได้จัดการตามสัญญาเช่าซื้อในภาระที่เหลือกับ
                                    บริษัทไฟแนนซ์และมีอีกหลายกรณีที่ผู้ซื้อยังได้ขายต่อรถไปอีกหลายทอด จนสุดท้ายก็
                                    ไม่รู้ว่ารถอยู่ที่ไหนอยู่กับใคร แต่ท่านก็ยังต้องเป็นจำเลยของบริษัทไฟแนนซ์และต้องใช้หนี้
                                    ให้เขาจนครบ โดยที่ท่านไม่ได้รถกลับคืนด้วยในทางกลับกัน ถ้าท่านเป็นผู้ซื้อหรือผู้รับ
                                    โอนสิทธิการเช่าซื้อรถที่ยังติดภาระเช่าซื้อ ท่านก็ต้องปฏิบัติตามขบวนการดังกล่าวข้างต้น
                                    เช่นกัน มิฉะนั้นสมมุติท่านเป็นคนดี ปฏิบัติตามสัญญาจนครบสัญญาท่านก็อาจจะ
                                    โอนสิทธิรถคันนี้เป็นของท่นไม่ได้เหมือนกัน ถ้าเอกสารหลักฐานตามที่เขาเซ็นมอบไว้ไม่สมบูรณ์ หรือต่อมาหลักฐานต่าง ๆ บางอย่างหมดอายุ
                                    ท่านก็อาจจะไม่สามารถตามเขาได้อีกเช่นกัน รถก็โอนกรรมสิทธิ์เป็นของท่านไม่ได้ให้ตรวจดูว่าสมุดคู่มือจดทะเบียนระบุหมายเลขทะเบียนรถ
                                    สีรถ หมายเลขเครื่องยนต์และตัวถังรถ ตรงกับรถที่เราซื้อหรือไม่ เล่มทะเบียนเป็นเล่มต้นฉบับหรือไม่ ถ้าเป็นเล่มที่แจ้งหายคัดใหม่ ต้องพิจารณา
                                    เป็นพิเศษ
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-2">
                            <div className="col-12 col-md-6 col-lg-6 ">

                                <p id="textindent2">
                                    ถ้ากรรมสิทธิ์ที่ระบุในเล่มมีมากี่ลำดับแล้ว ลำดับสุดท้ายต้องเป็นของบุคคลที่ขาย
                                    รถให้ท่าน ถ้าไม่ใช่ต้องมีชุดโอนกรรมสิทธิ์ที่เจ้าของคนสุดท้ายเซ็นโอนแล้วแนบมาด้วย
                                    พร้อมกับหลักฐานครบถ้วน อื่น ๆ ประวัติรถ เรื่องต่าง ๆ ภายในทะเบียนท่านเล่ม ขอให้
                                    ท่านที่ต้องทำธุรกรรมดังกล่าวถือปฏิบัติตามขั้นตอนอย่างเคร่งครัด อย่าประมาทเด็ดขาด
                                    ก็จะลดความเสี่ยงของทุกฝ่ายได้ในระดับหนึ่ง และจะไม่มาต้องเสียใจและเสียดายภายหลัง
                                </p>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 text-center">
                                <img src={imagecontent4} alt="imagecontent4" width="75%" />
                            </div>
                        </div>
                        <hr />
                    </>
                )}
                <Link id="noneline" onClick={handleshowdata3}>
                    <li>แนวทางการสืบชู้สาวนั้นนักสืบจะต้องสืบหาข้อมูลอะไรบ้างเพื่อใช้เบ็นหลักฐานในการฟ้องหย่าและเรียกค่าทดแทน</li>
                </Link>
                {showdata3 && (
                    <>
                        <div className="mt-3">   </div>
                        <div className="text-center">
                            <h3 style={{ color: "red" }}>แนวทางการสืบชู้สาวนั้นนักสืบจะต้องสืบหาข้อมูลอะไรบ้างเพื่อใช้เบ็นหลักฐานในการฟ้องหย่า <br />และเรียกค่าทดแทน</h3>
                        </div>
                        <div className="row justify content-center mt-2">
                            <div className="col-12 col-md-6 col-lg-6">
                                <p style={{ color: "red" }}>การสืบชู้สาวนั้นนักสืบจะต้องสืบหาข้อมูลอะไรบ้าง <br />
                                    เพื่อใช้เป็นหลักฐานในการฟ้องหย่าและเรียกค่าทดแทนจากชู้</p>
                                <p>- นักสืบจะต้องสืบหาข้อมูลด้านการเงิน ของสามี ภริยา หรือของชู้ เพราะมีความเป็นไป
                                    ได้ค่อนข้างสูงที่จะมีการโอนเงินให้กันทั้งสองฝ่าย หรือมีการเขียนเช็คให้ หรือมีการซื้อ
                                    อสังหาริมทรัพย์ต่างๆ อาจเป็นการเช่าที่พักอาศัยให้แก่กัน และอาจมีการใช้บัตรเครดิต
                                    ซื้อสินค้าและการใช้สอยต่างเกิดขึ้นได้</p>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 text-center">
                                <img src={imagecontent5} alt="imagecontent5" width="80%" />
                            </div>
                        </div>
                        <div className="row justify content-center ">
                            <div className="col-12">
                                <p>- นักสืบจะต้องเช็คหาข้อมูลการใช้โทรศัพท์ของสามี ภริยา หรือของชู้ เพราะตามปกติแล้ว
                                    คนที่มีชู้หรือเป็นชู้นั้น จะต้องมีการสื่อสารติดต่อกันทางโทรศัพท์หรือส่งข้อความหาชู้อย่างตลอดเวลาอย่างแน่นอน
                                    <br />
                                    - นักสืบจะต้องสืบหาข้อมูลการถือครองอสังหาริมทรัพย์ โฉนดบ้าน ที่ดิน ทาวน์เฮาส์
                                    คอนโด ชื่อของผู้เช่าว่าใครเป็นผู้มีกรรมสิทธิ์ในทรัพย์สินดังกล่าวอย่างแท้จริงหรือสืบ
                                    หาที่มาของเงินที่ใช้ซื้อทรัพย์สินเหล่านั้น
                                    <br />
                                    - นักสืบจะต้องเช็ดหาข้อมูลเกี่ยวกับชู้ ว่าเป็นใคร อยู่ที่ไหน ทำอาชีพอะไร มีสามี หรือ
                                    ภรรยาอยู่แล้วหรือไม่อย่างไร
                                    <br />
                                    - นักสืบจะต้องสืบหาข้อมูลเกี่ยวกับการใช้ชีวิตประจำวันของสามี หรือกรรยาที่มีชู้หรือ
                                    เป็นชู้ ด้วยการสะกดรอยตาม พร้อมถ่ายภาพ คลิปวีดีโอ บันทึกเสียง เพื่อเก็บไว้เป็น
                                    หลักฐานว่ามีการพบเจอกันที่หนและวันและเวลาะไร หลักฐานต่างๆที่ได้ดังกล่าวนั้น
                                    จะเป็นสิ่งที่ยืนยันได้ว่าสามีหรือกรรยาของคุณ มีชู้หรือป็นชู้กับใครหรือไม่ ถ้ามีหลักฐานที่น่เชื่อถือมากพอก็สามารถใช้ฟ้องหย่า เรียกค่าเสีย
                                    หาย ค่าทดแทน จากสามี ภริยา ที่มีชู้หรือเป็นชู้กับผู้อื่นได้</p>
                            </div>
                        </div>
                    </>
                )}
                <Link id="noneline" onClick={handleshowdata4}>
                    <li>สัญญาณแฟนมีกิ๊ก อ่านไว้เพื่อระวัง</li>
                </Link>
                {showdata4 && (
                    <>
                        <div className="mt-3"></div>
                        <div className="text-center">
                            <h3 style={{ color: "red" }}>สัญญาณแฟนมีกิ๊ก อ่านไว้เพื่อระวัง !</h3>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <li>แฟนเรามักจะมีมุมส่วนตัวเสมอ โดยเฉพาะการคุยโทรศัพท์ หรือ ท่องโลกโซเชียล
                                    เน็ตเวิร์ด เขาจะพยายามเดินให้ห่างจากคุณที่สุด หรือพยายามหลบๆซ่อนๆกระทำ
                                    บางอย่างที่ลับตาคุณ
                                    กรณีที่คุณอยู่ห่างกับแฟน ถ้าคุณคิดถึงแฟนแล้วอยากไปหาเอามากๆ แต่เป็นฝ่ายแฟน
                                    คุณที่ปฏิเสธไม่ยอมให้คุณไปที่ห้องตลอด แม้ว่าเมื่อก่อนเคยไปมาหาสู่กันได้บ่อยๆ
                                    ระวังไว้เลยว่คุณกำลังจะถูกสวมเขาเต็มๆ เพราะพฤติกรรมไม่อยากให้เราเข้าไปใน
                                    ห้องแบบนี้ก็มีเหตุผลรองรับอยู่อย่างน้อย 2 เหตุผลนั่นก็คือ เขาต้องการใช้เวลาส่วนตัว
                                    มากขึ้น และ ในห้องอาจจะมีอะไรบางอย่างซ่อนอยู่ (โอกาสมีกิ๊ก 70%)</li>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 text-center">
                                <img src={imagecontent6} alt="imagecontent6" width="100%" />
                            </div>
                        </div>
                        <li>สายเรียกซ้อนบ่อยๆนี่แหละตัวสำคัญ แต่ต้องเข้าใจว่าคนที่ใช้ไอโฟนอาจจะได้รับผลเสีย
                            มากหน่อย เพราะไอโฟนจะไม่โชว์สายเรียกซ้อนหากเราโทรไปตรงกับคนอื่นที่เขากำลัง
                            คุยอยู่ ดังนั้นถ้าอยู่ในสถานการณ์เสี่ยงว่าแฟนกำลังมีกิ๊ก พยายามให้เขาใช้โทรศัพท์ยี่ห้อ
                            อื่นดีกว่า (โอกาส 80%)</li>
                        <li>ถุงยางอนามัยก็สำคัญไม่แพ้กัน พยายามนับทุกครั้งที่ห่างกันว่าเขาเหลือกี่ชิ้น ดูราย
                            ละเอียดว่ายี่ฮ้ออะไร รุ่นไหน ขนาดเท่าไร เพราะหากเขาใช้ไปจะได้จดจำได้ว่าตรงกับที่
                            เราสำรวจไหม? เพราะผู้ชายส่วนใหญ่ไม่ได้สนใจรายละเอียดเรื่องนี้มากนักทำให้เขา
                            ละเลยที่จะมองในเรื่องนี้ (90%)</li>
                        <li> จับโทรศัพท์ โน๊ตบุ๊คส่วนตัว เขาไม่ได้อีกต่อไป เรื่องนี้เกิดขึ้นบ่อยมากๆในชีวิตคู่ ถึงแม้
                            แรกๆที่คบกันคุณจะได้รับรู้ทุกอย่างในโทรศัพท์ของเขา แต่เมื่อเวลาแฟนเรามีกิ๊กคุณจะไม่มีสิทธิ์รับรู้อะไรอีกต่อไป รหัสเฟสของเขาที่คุณเคย
                            มีไว้ในครอบครองก็จะถูกเปลี่ยนเพื่อให้คุณเขามาสอดส่องไม่ได้ ไลน์ที่เคยเช็คบ่อยๆก็ ถูกใส่รหัสให้หนาแน่นกว่าเดิม ถ้าเป็นแบบนี้เตรียม
                            น้ำตาตกในได้เลย (90%)</li>
                        <li>คำพูดที่ไม่ค่อยพูด คำพูดที่ไม่เคยพูดได้ถูกปล่อยออกมาจากปากแฟนของเรา แบ่งเป็นด้านดีและด้านแย่</li>
                        <div className="text-center">
                            <h3> <b>"สัญญาณบอกเหตุว่าแฟนคุณกำลังอาจจะมีมีกิ๊ก อ่านไว้เพื่อระวัง <br />ลองสังเกตสืบกันดู ถ้าเข้าข่ายตรงเกือบทุกข้อ <br />
                                ก็เชื่อได้เลยว่าคู่หรือแฟนของคุณกำลังนอกใจคุณอยู่อย่างไม่ต้องสงสัยครับ"</b> </h3>
                        </div>
                    </>
                )}
                <Link id="noneline" onClick={handleshowdata5}>
                    <li>การซื้อขายจากอินเตอร์เน็ตที่ต้องพึ่งระวัง</li>
                </Link>
                {showdata5 && (
                    <>
                        <div className="mt-3"></div>
                        <div className="text-center">
                            <h3 style={{ color: "red" }}>การซื้อขายจากอินเตอร์เน็ตที่ต้องพึ่งระวัง</h3>
                        </div>
                        <div className="row justify-content-center mt-2">
                            <div className="col-12 col-md-6 col-lg-6">
                                ทุกวันนี้มีการซื้อขายในโลกอินเตอร์เน็ตมากมายและมักจะมีมิจฉาชีพแฝงตัวมา
                                หลอกให้มากมายและเป็นคดีความทุกวัน
                                <p id="textindent2">
                                    เรามาดูลักษณะที่ต้องพึ่งระวังว่าจะเป็นลักษณะใดบ้างที่เข้าข่ายน่าสงสัยพฤติ
                                    กรรมฉ้อโกงสินค้าผ่านอินเทอร์เน็ต ที่น่าสงสัยและเข้าข่ายเป็นกลุ่มมิจฉาชีพ มีดังนี้
                                    <br />
                                    -ขายสินค้าราคาถูกกว่าท้องตลาด
                                    ประกาศขายสินค้าราคาถูกเกินจริง ไม่แจ้งที่อยู่หรือชื่อร้านาให้ชัดเจน ตัวอย่าง
                                    สินค้าเช่น โทรศัพท์มือถือ และโน้ตบุ๊ก
                                    หลอกล่อให้โอนเงินก่อนได้รับสินค้า
                                    <br />
                                    - พูดน้มน้าวให้ผู้ซื้อตายใจ และให้โอนเงินค่าสินค้าล่วงหน้าโดยไม่ให้หลักฐานเพื่อสร้างความ น่าเชื่อถือ
                                    เมื่อโอนเงินแล้ว ผู้ขายจะหายไป ไม่รับโทรศัพท์ และปิดโทรศัพท์หนีในที่สุด
                                </p>

                            </div>
                            <div className="col-12 col-md-6 col-lg-6 text-center">
                                <img src={imagecontent7} alt="imagecontent7" width="100%" />
                            </div>
                        </div>
                        <div className="row justify-content-center mt-2">
                            <div className="col-12 col-md-6 col-lg-6 text-center">
                                <img src={imagecontent8} alt="imagecontent8" width="100%" />
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <p>- ภายหลังการโอนเงินให้แล้วท่านจะไม่สามารถติดต่อมิจฉาชีพเหล่านี้ได้เลย เพราะมี
                                    การเปลี่ยนชื่อ และเบอร์โทรศัพท์ไปเรื่อยๆ ในการลงประกาศ
                                    <br />
                                    - เปลี่ยนชื่อและเบอร์โทรศัพท์ไม่ซ้ำกัน ทำให้ยากต่อการติดตาม เมื่อเกิดเหตุการณ์เช่นนี้
                                    แล้ว สิ่งที่ควรปฏิบัติ คือ
                                    <br />
                                    1. บันทึกหลักฐานรายละเอียดของประกาศหรือกระทู้นั้น ไว้เป็นหลักฐาน เช่น
                                    ชื่อ-สกุล/ อีเมล์/ เบอร์โทรศัพท์ โดยเซฟหน้าเว็บไซต์นั้นไว้และปริ้นออกมาเป็นเอกสาร
                                    ก่อนที่มิจฉาชีพจะเข้าไปลบประกาศ
                                    <br />
                                    2. บันทึกและเก็บหลักฐานในการโอนเงิน เช่น ชื่อบัญชี ชื่อธนาคาร/ เลขที่บัญชี/ สาขา
                                    <br />
                                    3. แจ้งความกับตำรวจว่า "ถูกฉ้อโกง" เพื่อลงบันทึกประจำวัน และออกใบแจ้งความเพื่อดำเนินคดี</p>
                            </div>
                        </div>
                    </>
                )}
                <hr />
                <Fade >
                    <div className="row justify-content-center">
                        <div className="text-center my-3">
                            <h1 id="red"><b>ติดต่อเรา The Spy</b></h1>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 text-center">
                            <label htmlFor=""><h2><b>โทรศัพท์</b></h2></label>
                            <Link id='noneline' to="tel:0876394141" target="_blank">
                                <h3><b>087-639-4141</b></h3>
                            </Link>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 text-center">
                            <label htmlFor=""><h2><b>Line ID</b></h2></label>
                            <Link id='noneline' to="http://line.me/ti/p/0okvKJiUkD" target="_blank">
                                <h3><b>thespythai</b></h3>
                            </Link>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 text-center">
                            <label htmlFor=""><h2><b>Email</b></h2></label>
                            <Link id='noneline' to="mailto:the_spythai@hotmail.com" target="_blank">
                                <h3><b>the_spythai@hotmail.com</b></h3>
                            </Link>
                        </div>
                    </div>
                </Fade>
            </div>
            <hr />
        </>
    )
}