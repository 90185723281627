import { Link, useLocation } from "react-router-dom"
import Logo from "../images/logo.png"
import Logoline from "../images/logoline.png"
import Logophone from "../images/phone.png"


export default function Navbar() {
    const location = useLocation()
    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
                <Link to="/" className="navbar-brand">
                    <img src={Logo} alt="logo" width="230px" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="navbarText">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} aria-current="page" to={"/"}>หน้าแรก</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === '/About' ? 'active' : ''}`} to={"/About"}>เกี่ยวกับเรา</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === '/Service' ? 'active' : ''}`} to={"/Service"}>งานบริการ</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === '/knowledge' ? 'active' : ''}`} to={"/knowledge"}>เกร็ดความรู้</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === '/Contact' ? 'active' : ''}`} to={"/Contact"}>ติดต่อเรา</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={"http://line.me/ti/p/0okvKJiUkD"} target="_blank">
                                <img src={Logoline} alt="" width="50px" />
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={"tel:0876394141"} target="_blank">
                                <img src={Logophone} alt="" width="50px" />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
